
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --color-lightblue: #F3F9FB;
  --color-lightgray:#666666
  --font-family: 'Poppins';
}

* {
  box-sizing: border-box;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.swiper-button-disabled{
  display:none;   
}
.swiper-button-next{
  display: none;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev .swiper-button-next
{
  display: none !important;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
  display: none !important;
}
.swiper-pagination-bullet-active{
  width: 20px !important;
  border-radius: 5px !important;
}
html,body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
