.App {
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100vw;
  flex-direction: column;
}
.WihtePsace{
  width: 10px;
  height: 100px;
}
.card-cart{
  
    width: 95%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
  
}
.App-logo {
  pointer-events: none;
}
.CardFlex{

  width: calc(90vw - 290px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
}
.absolut{

  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #008ECC;
  /* padding: 5px; */
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  font-family: 'Poppins';
  font-weight: 500;
  color: white;
  font-size: 1em;
  border-bottom-left-radius: 10px;



}
.card {
  width: 200px;
  background: #EDEDED;
  border-radius: 8px;
  border: 1.2px solid #EDEDED;
  overflow: hidden;
  position: relative;
}
.card-related {
  width: 100px;
  min-width: 100px;
  overflow: hidden;
  position: relative;
}
.card-img-related {
  width: 100%;
  height: 100px;

  background: #EDEDED;
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Product-container {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: 'space-between';
  margin-top: 45px;
}
.product-item {
  width: 38%;
  /* display: flex; */
}

.product-item-class{  display: flex;
  width: 58%;
flex-direction: row-reverse;
gap: 10px;}
.MainImage {
  width: 60%;
  border-radius: 10px;

}
.MainImage img{
  width: 70%;
  object-fit: contain;
  border-radius: 10px;
  
}
.carousel-item img{
  width: 80%;
  border-radius: 10px;
}
.carousel {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}
.carousel-item {
  width: 70%;
  border: 1px solid #eeeeee;
}
.Rating {
  flex-direction: row;
  display: flex;
  gap: 3px;
  margin-top: 10px;
}
.Rating span {
  padding-left: 5px;
  font-family: 'Poppins';
  font-size: 0.8em;
  color: gray;
}
ul.product-specification {
  padding: 0;
  margin: 0;
  padding-left: 15px;
  margin-top: 10px;
}
li.product-specification-item {
  text-align: left;
  font-family: 'Poppins';
  color: #585858;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 3px;
  font-size: 0.8em;
}
.product-choices {
  border-top: 0.5px solid lightgray;
  margin-top: 18px;
  border-bottom: 0.5px solid lightgray;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  gap: 18px;
}
.product-Row-Counter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
}
.product-Counter {
  display: flex;
  /* gap: 10px; */
}
.product-Counter-minus {
  border: 1px solid lightgray;
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  align-items: center;
  font-weight: 900;
}
.product-Counter-number {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-weight: 600;
}
.product-row-buy {
  display: flex;
  gap: 10px;
}
.product-headers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 20px;
  border-bottom: 0.5px solid lightgray;
  padding-bottom: 10px;
}
.product-headers-item {
  font-family: 'Poppins';
  font-weight: 400;
}
.descriptContainers {
  width: 100%;
  max-width: 700px;
  margin-top: 25px;
}
.descriptonpStyle {
  text-align: left;
  font-family: 'Poppins';
  font-weight: 600;
}
.Reviews {
  width: 100%;
  max-width: 700px;
}
.ReviewRow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  background-color: #eeeded;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.NameReview {
  font-family: 'Poppins';
  font-weight: 800;
}
.NameComment {
  font-family: 'Poppins';
  font-weight: 400;
  color: gray;
}
.product-row-buy-choice {
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  background: #008ECC;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Poppins';
  font-weight: 500;
}
.product-choices-item {
  font-family: 'Poppins';
  font-weight: 500;
  width: 100px;
  border: 1px solid lightgray;
  padding: 5px;
  font-size: 0.8em;
}
.Product-container {
  width: 100%;
  flex-direction: row;
  display: flex;
  gap: 30px;
  margin-top: 45px;
}
.product-item h1, h2, h3, p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.9em;
  padding-top: 5px;
  text-align: left;
  /* padding-top: 14px; */
}
.card-price-related h3 {
  text-align: left;
  font-family: 'Poppins';
  font-weight: 300;
  font-weight: 800;
  font-size: 0.7em;
  /* margin-top: 10px; */
}
.card-title-related h3 {
  text-align: left;
  font-family: 'Poppins';
  font-weight: 300;
  font-weight: 300;
  font-size: 0.7em;
  width: 100;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
  /* color: gray; */
}
.card-img-related img{
  width: 80px;
  height: 80px;
  object-fit: contain;

  justify-content: center;
  align-items: center;
  display: flex;
}
.card-img {
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.card-img-cart{
  width: 30%;
  max-width: 200px;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.special {
  font-family: 'Poppins';
  font-size: 0.8em;
  text-align: left;
  padding-left: 10px;
}
.card-img img{width: 80%;
height: 80%;
object-fit: contain;}
.card-body {
  background: white;
  font-family: "Poppins'";
  font-weight: 400;;
} 
.card-body-cart{
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  min-width: 300;
  flex-wrap: wrap;
}
.card-title {

  font-family: 'Poppins';
  font-weight: 400;
  padding-top: 10px;
  text-align: left ;
}
.inputcontainer{

  margin-top: 26px;
  color: #3f9fbf;
  font-family: 'Poppins';
  font-weight: 400;
  text-align: left;

}
.dataRow {
  margin-top: 10px;
  font-family: 'Poppins';
  letter-spacing: 0.5px;
  text-align: left;
  color:gray
}
.dataRow span{color: black;
}
.footer {
  width: 100%;
  background: #05ABF3;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 100px;
  align-content: center;
  justify-content: center;
  display: flex;
}
img.Bubble {
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 5;
  width: 255px;
}
.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  /* justify-content: center; */
  margin-left: 5%;
  padding-top: 60px;
  /* margin-top: 20px; */
}
.firstRow {
  width: 100%;
  z-index: 13;
  max-width: 300px;
}
.firstRow {
  display: flex;
  flex-direction: column;
}
.bubbleLogo{

  position: relative;
  z-index: 143433;
  width: 129px;
  margin-bottom: 31px;
}
.bubbleFinis{

  margin-top: 10px;
  padding-top: 10px;
  text-align: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 0.1px solid lightgray;
  font-size: 0.8em;
  color: white;
  font-family: 'Poppins';
  padding-bottom: 15px;
}
.firstRow h2 {
  font-family: 'Poppins';
  font-size: 1.4em;
  font-weight: 600;
  color: #def2fe;
  margin-top: -10px;
}
.bubbleImageCOntainer{
  
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 25px;
    padding-left: 10px;

}
img.itemRowIcon {
  width: 125px;
}
i.FindITData {
  margin-top: 10px;
}
ul.FindIT {
  color: #def2fe;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1em;
  margin-top: 31px;
}
.FinalThirdRow{

  max-width: 280px;
  min-width: 280px;
  width: 33%;
}
.notification_update{

}
.rowTotal {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.8em;
}
.AuthText{


  display: flex;
  flex-direction: column;
  text-align: left;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  font-weight: 400;
  color: gray;
}
.AuthText span{
  
    font-weight: 600;
    font-size: 0.8em;
    margin-top: -10px;
    color: #3f9fbf;
    text-align: left;
}
.inputItem{

  width: 100%;
  min-width: 200px;
  max-width: 500px;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 2px;
  margin-top: 5px;
}
.hollowbutton{

  padding: 5px;
  font-family: 'Poppins';
  font-weight: 600;
  background: white;
  border: 2px solid #3f9fbf;
  color: #3f9fbf;
  border-radius: 5px;
  font-size: 0.8em;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;

}
.button{

  padding: 5px;
  font-family: 'Poppins';
  font-weight: 600;
  background: #3f9fbf;
  color: white;
  border-radius: 5px;
  font-size: 1em;
  padding-left: 20px;
  padding-right: 20px;

}
.card-title h3 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.8em;
  width: 90%;
  padding-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  
}
.card-price{
  padding-top: 5px;
}
.card-price h3 {
  font-family: 'Poppins';
  font-size: 0.8em;
  text-align: left;
  padding-left: 10px;
}
.card-price h3 span {
  font-weight: 400;
}

.card-price-save{color: rgb(36, 155, 62);
padding: 0;
margin: 0;
width: 100%;
text-align: left;
padding-left: 10px;
padding-bottom: 10px;
font-family: 'Poppins';
font-weight: 600;
height: 35px;
font-size: 0.8em;}
.card-price-product {
  padding-top: 5px;
}
.Product-container{
  max-width: 1090px;
}

.card-price-product h3 {
  text-align: left;
  width: 100%;
  padding: 0px;
  margin-top: 10px;
  margin: 0px;
  font-size: 1.4em;
  font-weight: 600;
  margin-top: 6px;
}
.card-price-product h3 span {
  font-weight: 400;
  padding-left: 15px;
  color: gray;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 600px) {
  .Product-container{
    flex-direction: column;

  }
  .carousel{
    width: 20%;
  }
  .MainImage{
    width: 80%;
  }
  .product-item{
    width: 100%;
  }
  .product-item-class{
    width: 100%;
  }
  .firstRow{

    margin-top: 20px;  }
  .footer-container {
    flex-direction: column;
    gap: 20px;
  

  }
}
@media (max-width: 768px) {
  .shopBy{
    gap:20px;
  }
 .CardFlex{
  justify-content: space-around;
  width: 100%;
  gap:0px;
 }
 .card{
  width: 150px;
  margin-top: 20px;
 }

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
