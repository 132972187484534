.search{
    background-color: var(--color-lightblue);
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    gap: 10px;
    position: relative;
    
}

.searchText{
    font-family: var(--font-family);
    font-size: 0.7em;
    color:var(--color-lightgray);
    letter-spacing: 0.04em;
    width: 100%;
    height: 100%;
    border-width: 0px;
    font-weight: 300;
    background-color: transparent;
    outline: none;
}